import React from "react";

export const CompanyPage = () => {
    document.title = '会社概要 | 有限会社東和ハウジング 宮崎県日向市'
    return (
        <section className="company">
        <div className="company-inner">
            <h1 className="titleBar01">会社概要</h1>
            <dl className="company-profile">
                <dt>会社名</dt>
                <dd>有限会社東和ハウジング</dd>
                <dt>事業内容</dt>
                <dd>
                    土地・建物売買の仲介<br/>
                    アパート・貸家・マンション・店舗の賃貸の仲介<br/>
                    アパート・貸家・マンション・店舗の賃貸の管理業<br/>
                    賃貸住宅管理業者<br/>
                    国土交通大臣（０１）第００４７５４号
                </dd>
                <dt>業種</dt>
                <dd>不動産業</dd>
                <dt>免許証番号</dt>
                <dd>宮崎県知事（５）第４３７３号</dd>
                <dt>設立年月日</dt>
                <dd>平成元年設立　平成１６年７月法人に変更</dd>
                <dt>代表取締役</dt>
                <dd>和田克弘</dd>
                <dt>宅地建物取引士	</dt>
                <dd>
                    和田克弘（第２５９０号）<br/>
                    公認不動産コンサルティングマスター<br/>
                    　　　　　（２）第３１０１５号<br/>
                    二級建築施工管理技士<br/>
                    和田有世（第６８８３号）<br/>
                    和田三枝（第３５６７号）
                </dd>
                <dt>
                    全国賃貸不動産<br/>
                    管理業協会会員
                </dt>
                <dd>
                    会員番号　０３３４８
                </dd>
                <dt>賃貸不動産<br/>
                    経営管理士</dt>
                <dd>和田克弘（登録番号（２）００４２４４）	</dd>
                <dt>住所</dt>
                <dd>宮崎県日向市原町１丁目４番５号	
                    <div className="gmap">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d902.3915622617407!2d131.62856666117187!3d32.42332744753996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354715b05d7fefa7%3A0xfc4951815d901fb!2z77yI5pyJ77yJ5p2x5ZKM44OP44Km44K444Oz44Kw!5e0!3m2!1sja!2sjp!4v1676857055941!5m2!1sja!2sjp" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </dd>
                <dt>TEL/FAX</dt>
                <dd>
                    TEL : 0982-54-0606<br/>
                    FAX : 0982-54-0660
                </dd>
                <dt>営業時間</dt>
                <dd>ＡＭ８時３０分～ＰＭ６時３０・定休日：年末、年始のみ</dd>
            </dl>
        </div>
    </section>
    )
}